'use client'

import React from 'react'

export default function Custom404() {
  React.useEffect(() => {
    window.location.pathname = '/'
  })
  return <h1>🤷 Cette page n&apos;existe pas !</h1>
}
